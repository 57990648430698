var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-asignacion-tepsolicitud"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Detalle")]),_c('button',{ref:"closeModal1",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('label',[_vm._v("Vehiculo")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.vehiculo.placa),expression:"form.vehiculo.placa"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.vehiculo.placa.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.form.vehiculo.placa)},on:{"change":function($event){return _vm.buscarVehiculos()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form.vehiculo, "placa", $event.target.value)}}})])]),_c('div',{staticClass:"form-group col-md-4"},[_c('label',[_vm._v("Conductor")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.conductor.numero_documento),expression:"form.conductor.numero_documento"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.conductor.numero_documento.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.form.conductor.numero_documento)},on:{"change":function($event){return _vm.buscarConductores()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form.conductor, "numero_documento", $event.target.value)}}}),_vm._v(" "+_vm._s(_vm.form.conductor.nombres)+" "+_vm._s(_vm.form.conductor.apellidos)+" ")])])])]),_c('div',{staticClass:"modal-footer justify-content-between"},[(
              ((_vm.$store.getters.can('tep.solicitudes.asignar') &&
                !_vm.$v.form.$invalid) ||
                (_vm.$store.getters.can('tep.solicitudes.asignar') &&
                  !_vm.$v.data_solicitud.$invalid)) &&
                _vm.validarStatusViaje(_vm.data_solicitud.viajes)
            )?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }