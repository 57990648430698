<template>
  <div class="row">
    <div class="modal fade" id="modal-form-asignacion-tepsolicitud">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-4">
                <label>Vehiculo</label>
                <div>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.vehiculo.placa"
                    :class="
                      $v.form.vehiculo.placa.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @change="buscarVehiculos()"
                  />
                </div>
              </div>
              <div class="form-group col-md-4">
                <label>Conductor</label>
                <div>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.conductor.numero_documento"
                    :class="
                      $v.form.conductor.numero_documento.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @change="buscarConductores()"
                  />
                  {{ form.conductor.nombres }}
                  {{ form.conductor.apellidos }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                (($store.getters.can('tep.solicitudes.asignar') &&
                  !$v.form.$invalid) ||
                  ($store.getters.can('tep.solicitudes.asignar') &&
                    !$v.data_solicitud.$invalid)) &&
                  validarStatusViaje(data_solicitud.viajes)
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
export default {
  name: "TepSolicitudAsignacion",
  components: {},
  data() {
    return {
      moment: moment,
      cargando: false,
      data_solicitud: {
        conductor: {},
        vehiculo: {},
      },
      form: {
        conductor: {
          numero_documento: null,
        },
        vehiculo: {
          placa: null,
        },
        empresa_id: null,
      },
    };
  },
  validations() {
    return {
      form: {
        conductor: {
          numero_documento: {
            required,
          },
        },
        vehiculo: {
          placa: {
            required,
          },
        },
      },
      data_solicitud: {
        conductor: {
          numero_documento: {
            required,
          },
        },
        vehiculo: {
          placa: {
            required,
          },
        },
      },
    };
  },

  methods: {
    async llenar_modal(item) {
      this.data_solicitud = item;
    },
    async buscarConductores() {
      this.falgValidando = true;
      if (this.data_solicitud.conductor) {
        let dato = this.data_solicitud.conductor.numero_documento;
        let res = await this.validarStatus(dato, "C");
        if (res && res.length != 0) {
          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            this.data_solicitud.conductor = {};
            this.falgValidando = false;
            return false;
          }
          if (res.has_asignacion == 1) {
            this.data_solicitud.conductor.numero_documento = null;
            this.data_solicitud.conductor.nombres = null;
            this.data_solicitud.conductor.apellidos = null;
            this.$swal({
              icon: "error",
              title: `El conductor se encuentra activo dentro de otra solicitud`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          } else {
            this.data_solicitud.conductor = res[0];
          }
        } else {
          this.$swal({
            icon: "error",
            title:
              "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.data_solicitud.conductor = {};
        }
        this.falgValidando = false;
      } else {
        let dato = this.form.conductor.numero_documento;
        let res = await this.validarStatus(dato, "C");
        if (res && res.length != 0) {
          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            this.form.conductor = {};
            this.falgValidando = false;
            return false;
          }
          if (res.has_asignacion == 1) {
            this.form.conductor.numero_documento = null;
            this.form.conductor.nombres = null;
            this.form.conductor.apellidos = null;
            this.$swal({
              icon: "error",
              title: `El conductor se encuentra activo dentro de otra solicitud`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          } else {
            this.form.conductor = res[0];
          }
        } else {
          this.$swal({
            icon: "error",
            title:
              "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.conductor = {};
        }
        this.falgValidando = false;
      }
    },
    async buscarVehiculos() {
      this.falgValidando = true;
      if (this.data_solicitud.vehiculo) {
        let dato = this.data_solicitud.vehiculo.placa;
        let res = await this.validarStatus(dato, "V");
        if (res && res.length != 0) {
          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            this.data_solicitud.vehiculo.placa = null;
            this.falgValidando = false;
            return false;
          }
          if (res.has_asignacion == 1) {
            this.data_solicitud.vehiculo.placa = null;
            this.$swal({
              icon: "error",
              title: `El vehículo se encuentra activo dentro de otra solicitud`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          }
          if (res.has_tipo_operacion == 0) {
            this.data_solicitud.vehiculo.placa = null;
            this.$swal({
              icon: "error",
              title: `El vehículo no coincide con el tipo de operacion`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          } else {
            this.data_solicitud.vehiculo = res[0];
          }
        } else {
          this.$swal({
            icon: "error",
            title:
              "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          this.data_solicitud.vehiculo = null;
          return false;
        }
        this.falgValidando = false;
      } else {
        let dato = this.form.vehiculo.placa;

        let res = await this.validarStatus(dato, "V");
        if (res && res.length != 0) {
          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            this.form.vehiculo.placa = null;
            this.falgValidando = false;
            return false;
          }
          if (res.has_asignacion == 1) {
            this.form.vehiculo.placa = null;
            this.$swal({
              icon: "error",
              title: `El vehículo se encuentra activo dentro de otra solicitud`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          } else {
            this.form.vehiculo = res[0];
          }
        } else {
          this.$swal({
            icon: "error",
            title:
              "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          this.form.vehiculo = null;
          return false;
        }
        this.falgValidando = false;
      }
    },
    async validarStatus(dato, tipo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/tep/solicitudesTransporte/listaConductores";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/tep/solicitudesTransporte/listaVehiculos";
      }

      let params = {
        entidad,
        tipo_operacion: this.data_solicitud.tipo_operacion,
        dato,
        fecha: moment(this.data_solicitud.fecha_hora_inicio_servicio).format(
          "YYYY-MM-DD"
        ),
      };

      var res = null;
      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },
    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title: "Aviso en documentacion",
          showConfirmButton: false,
          showCloseButton: true,
          footer:
            "<small style='text-align:center'><b>Desde el 1 de septiembre se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas información comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
        });
      }
    },
    validarStatusViaje(viajes){
      if(viajes.length > 0){
        return viajes[0].estado > 2 ? false:true;
      }else{
        return true;
      }
    },
    save() {
      if (!this.$v.data_solicitud.$invalid || !this.$v.form.$invalid) {
        if (this.data_solicitud.conductor && this.data_solicitud.vehiculo) {
          this.cargando = true;
          axios({
            method: "POST",
            url: "/api/tep/solicitudesTransporte/asignar",
            data: {
              form: this.data_solicitud,
              solicitud: this.data_solicitud.id,
            },
          })
            .then(() => {
              this.cargando = false;
              this.$swal({
                icon: "success",
                title: "Los datos se guardaron exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        } else {
          this.cargando = true;
          axios({
            method: "POST",
            url: "/api/tep/solicitudesTransporte/asignar",
            data: {
              form: this.form,
              solicitud: this.data_solicitud.id,
            },
          })
            .then(() => {
              this.cargando = false;
              this.$swal({
                icon: "success",
                title: "Los datos se guardaron exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },
  },
};
</script>
